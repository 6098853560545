import { createSlice } from '@reduxjs/toolkit';

const controlDefaults = {
  listing: true,
  detail: false,
  edit: false,
  create: false,
  fullScreen: false,
  activate: false,
  newUser: false,
  existingUser: false,
  activationPayment: false,
};

const { reducer, actions } = createSlice({
  name: 'devices',
  initialState: {
    items: {},
    selectedId: null,
    groupedDeviceIds: [],
    openGroupDevices: false,
    scrollValue: null,
    controls: controlDefaults,
    nonActiveTrackers: [],
    nonActiveTrackersIds: [],
    toBeActiveTrackers: [],
    directOpenRenewPlan: false,
    commands: [],
    fetched: false,
    opentNonActiveTrackersModal: false,
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      state.fetched = true;
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    update(state, action) {
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    select(state, action) {
      state.selectedId = action.payload;
      if (!action.payload) {
        state.scrollValue = null;
      }
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
    addGroupedDeviceIds(state, action) {
      state.groupedDeviceIds.push(action.payload);
      state.groupedDeviceIds = [...new Set(state.groupedDeviceIds)];
    },
    setOpentNonActiveTrackersModal(state, action) {
      state.opentNonActiveTrackersModal = action.payload;
    },

    removeGroupedDeviceIds(state, action) {
      state.groupedDeviceIds = state.groupedDeviceIds.filter((id) => id !== action.payload);
    },
    resetGroupedDeviceIds(state) {
      state.groupedDeviceIds = [];
    },
    setOpenGroupDevices(state, action) {
      state.openGroupDevices = action.payload;
    },
    toggleOpenGroupDevices(state) {
      state.openGroupDevices = !state.openGroupDevices;
    },
    setScrollValue(state, action) {
      state.scrollValue = action.payload;
    },
    resetScrollValue(state) {
      state.scrollValue = null;
    },
    setHideListing(state) {
      console.log('sethidefullscreen');
      state.controls = {
        ...controlDefaults,
        listing: false,
      };
    },
    setShowListing(state) {
      state.controls = {
        ...controlDefaults,
        listing: true,
        activate: false,
      };
      state.directOpenRenewPlan = false;
      state.opentNonActiveTrackersModal = false;
    },
    setControl(state, action) {
      console.log('controlfullscreen');
      const { key, value } = action.payload;
      state.controls[key] = value;
    },
    setDetail(state) {
      console.log('detailfullscreen');
      state.controls = {
        ...controlDefaults,
        listing: false,
        detail: true,
      };
    },
    setEdit(state) {
      console.log('editfullscreen');
      state.controls = {
        ...controlDefaults,
        listing: false,
        edit: true,
      };
    },
    setAdd(state) {
      state.controls = {
        ...controlDefaults,
        listing: true,
        create: true,
      };
    },
    setFullScreen(state) {
      console.log('fullscreen');
      state.controls = {
        ...state.controls,
        listing: false,
        fullScreen: true,
      };
    },
    unSetFullScreen(state) {
      state.controls = {
        ...state.controls,
        listing: !(state.controls.create || state.controls.edit || state.controls.detail),
        fullScreen: false,
      };
    },
    resetControl(state) {
      state.controls = controlDefaults;
    },
    setNonActiveTrackers(state, action) {
      state.nonActiveTrackers = action.payload;
    },
    resetNonActiveTrackersCount(state) {
      state.nonActiveTrackers = [];
    },
    setExistingUserNonActive(state) {
      state.controls = {
        ...controlDefaults,
        listing: true,
        activate: true,
        existingUser: true,
      };
    },
    setNewUserNonActive(state) {
      state.controls = {
        ...controlDefaults,
        listing: true,
        activate: true,
        newUser: true,
      };
    },
    setNonActiveTrackersControl(state) {
      state.controls = {
        ...controlDefaults,
        listing: true,
        activate: true,
      };
    },
    addAllNonActiveTrackersIds(state, action) {
      state.nonActiveTrackersIds = action.payload;
    },
    addNonActiveTrackersIds(state, action) {
      state.nonActiveTrackersIds.push(action.payload);
      state.nonActiveTrackersIds = [...new Set(state.nonActiveTrackersIds)];
    },
    removeNonActiveTrackersIds(state, action) {
      state.nonActiveTrackersIds = state.nonActiveTrackersIds.filter((id) => id !== action.payload);
    },
    resetNonActiveTrackersIds(state) {
      state.nonActiveTrackersIds = [];
    },
    setToBeActiveTrackers(state, action) {
      state.toBeActiveTrackers = action.payload;
    },
    resetToBeActiveTrackers(state) {
      state.toBeActiveTrackers = [];
    },
    setActivationPaymentTrackersControl(state) {
      state.controls = {
        ...controlDefaults,
        listing: true,
        activate: true,
        activationPayment: true,
      };
      state.directOpenRenewPlan = false;
    },
    setDirectOpenRenewPlan(state) {
      state.directOpenRenewPlan = true;
    },
    resetDirectOpenRenewPlan(state) {
      state.directOpenRenewPlan = false;
    },
    setCommands(state, action) {
      state.commands = action.payload;
    },
  },
});

export { actions as devicesActions };
export { reducer as devicesReducer };
